class LexicanSearch {
  constructor() {
    // Initialize the search wrapper
    this.archiveListBlock = document.querySelector(
      ".js-archive-search-wrapper"
    );
    if (!this.archiveListBlock) {
      console.error("Archive list block not found");
      return;
    }

    // Initialize components within the wrapper
    this.searchForm = this.archiveListBlock.querySelector(".js-search-form");
    this.searchInput = this.searchForm?.querySelector(".js-search-form--input");
    this.submitButton = this.searchForm?.querySelector('button[type="submit"]');
    this.searchResultLists =
      this.archiveListBlock.querySelectorAll(".js-search-list");
    this.searchSuggestions =
      this.searchForm?.querySelectorAll("[data-search-text]");
    this.noItemsFoundMessage =
      this.archiveListBlock?.querySelector(".js-no-results");

    // Do nothing if there are no search results or search input
    if (!this.searchInput || !this.searchResultLists.length) {
      console.error("Search input or search result lists not found");
      return;
    }

    // Setup event listeners
    this.setupEventListeners();
  }

  setupEventListeners() {
    // Input and change event listener for performing search
    if (this.searchInput) {
      this.searchInput.addEventListener("input", this.handleSearch.bind(this));
      this.searchInput.addEventListener("change", this.handleSearch.bind(this));

      // Check if the current URL contains a search parameter
      const urlParams = new URLSearchParams(window.location.search);
      const searchParam = urlParams.get("search");
      if (searchParam) {
        this.searchInput.value = searchParam;
        this.handleSearch();
      }
    }

    // Click event listener for the submit button
    if (this.submitButton) {
      this.submitButton.addEventListener("click", (e) => {
        e.preventDefault();
        this.handleSearch();
      });
    }

    // Click event listeners for suggestions
    this.searchSuggestions?.forEach((suggestion) => {
      suggestion.addEventListener("click", (e) => {
        e.preventDefault();
        this.searchInput.value = suggestion.getAttribute("data-search-text");
        this.searchInput.dispatchEvent(new Event("input"));
      });
    });
  }

  handleSearch() {
    const searchValue = this.searchInput.value.toLowerCase();
    let matchingItems = 0;

    this.searchResultLists.forEach((list) => {
      const listItems = list.querySelectorAll("[data-search-title]");
      let hiddenCount = 0;

      // Do nothing if there are no list items
      if (!listItems) {
        console.error("List items not found");
        return;
      }

      listItems.forEach((item) => {
        const itemText = item.getAttribute("data-search-title").toLowerCase();
        const normalizedItemText = itemText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        // Toggle visibility based on search match
        if (normalizedItemText.indexOf(searchValue) !== -1) {
          matchingItems++;
          item.style.display = "block";
        } else {
          hiddenCount++;
          item.style.display = "none";
        }
      });

      // Toggle list visibility
      list.style.display = hiddenCount === listItems.length ? "none" : "block";
    });

    // If there are not any result that matches the search, show the no items found message
    if (this.noItemsFoundMessage) {
      if (matchingItems > 0) {
        this.noItemsFoundMessage.style.display = "none";
      } else {
        this.noItemsFoundMessage.style.display = "block";
      }
    }

    // Update the URL with the search value
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("search", searchValue);
    window.history.replaceState(
      {},
      "",
      window.location.pathname + "?" + urlParams.toString()
    );
  }
}

// Initialize the search functionality
new LexicanSearch();
